import React, { useState } from "react";
import ConfirmDialog from "./../../../ConfirmDialog";
import { connect } from "react-redux";
import { rebootDevice } from "../../../../actions";

const Index = (props) => {
  const { deviceInfo, rebootDevice, deviceUuid, setModal } = props;
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(true);
  const { stateMachine, displayName } = deviceInfo;
  const onConfirm = () => {
    if (stateMachine && stateMachine !== "OFF") {
      rebootDevice(deviceUuid);
    }
    setModal(false)
  };

  const computeText = (stateMachine, displayName) => {
    if (!displayName || displayName === 'all devices') {
      return "Please select a device.";
    }

    if (stateMachine === "OFF") {
      return `${displayName} is off, Please turn on the machine first.`;
    }
    return `Are you sure you want to reboot ${displayName}?`;
  };

  return (
    <div>
      <ConfirmDialog
        title={"Reboot"}
        children={computeText(stateMachine, displayName)}
        open={showLogoutConfirm}
        setOpen={setShowLogoutConfirm}
        onConfirm={onConfirm}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

export const mapStateToProps = (state) => {
  const selectedDevice = state.devices?.selectedDevice || null;
  const deviceInfo = state.devices[selectedDevice] || {};
  return {
    deviceUuid: selectedDevice,
    deviceInfo,
  };
};

export default connect(mapStateToProps, { rebootDevice })(Index);
