import React, { useEffect, useState } from 'react'
import ProcessedImage from "../../../../ProcessedImage";
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import { useWebServer } from "../../../../../providers";
import Draggable from "react-draggable";
import {
    ButtonGroup,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import ZoomIn from "@mui/icons-material/ZoomIn";
import ZoomOut from "@mui/icons-material/ZoomOut";
import DragIndicatorSharpIcon from "@mui/icons-material/DragIndicator";
import Star from "@mui/icons-material/Star";
import StarOutline from "@mui/icons-material/StarOutline";
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import {
    percUpHardLowClip,
    getHistogram,
} from "../../../../../utils/imageProcessing";
import {
    nextSpecimen,
    prevSpecimen,
    uploadSpecimenMetadata,
    uploadSpecimenBatchMetadata,
    updateSpecimenFromMetadata,
    setMetadataErrors,
    updateSpecimenAutoMetadata,
    updateSpecimenCaptureMetadata,
    fetchPlateWells,
    updatePlateWells,
    updateSpecimenTagOptions,
    jumpSpecimen
} from "../../../../../actions";
import {
    getAutocompleteOptions,
    getAutofillOverwriteCount,
    getDuplicateWells,
} from "../../../../../utils/autofill";
import {
    formatCaptureData,
    processGenusSpecies,
} from "../../../../Forms/SpecimenMetadataSubmit";

import { connect } from "react-redux";

import { RenderAutocompleteMultiple } from "../../../../Forms/renderAutocompleteMultiple";
// import SpecimenBBox from './SpecimenBBox'

// import AAA from '../../../ModalRouter/TrayViewer/AAA';

import { Form, Field } from "react-final-form";
import {

    Typography,
    Tooltip,
    Button,
} from "@mui/material";
// import SettingsIcon from "@mui/icons-material/Settings";
import {
    genusSpeciesAutoCompleteOptions,
    gonotrophicAutoCompleteOptions,
    lifeStatusAutoCompleteOptions,
} from "../../../../../utils/const";
import {
    DefaultWellAutocompleteOptions,
    DuplicateOptionsEnum,
    OverwriteOptionsEnum,
} from "../../../../../utils/const";
import {
    filterSortedList,
    insertSortedSet,
} from "../../../../../utils/helpers";
import {
    renderAutocomplete,
} from "../../../../Forms/FormFields";
import { getSppDisplayName } from "../../../../../utils/caseConvert";

import { specimenAutoFillDataSubmit } from "../../../../Forms/SpecimenMetadataSubmit";
import AutoSave from "./AutoSave";
import SexInput from "./Components/SexInput";
import {
    checkCommaAndBackSlash,
    checkCommaAndBackSlashOnTags,
    checkWellNumber,
} from "../../../../../lib/validationHelper";
import HiddenField from "./Components/HiddenField";
import _ from "lodash";
import { capitalize } from "../../../../../utils/caseConvert";
import { toggleEnlargedSpecimenModal } from '../../../../../../src/actions';
import { rx, ry } from "../../../../../utils/const";
import { diff } from "deep-object-diff";

const smallerSpecimenSx = {
    fontSize: [".5rem", ".5rem", ".5rem", "0.5rem", "1rem", "1rem"],
};

const EnlargedSpecimen = (props) => {
    const {
        /* eslint-disable no-unused-vars */
        nextSpecimen, prevSpecimen, frontImage, backImage, toggleEnlargedSpecimenModal, trayId, specimenId, trayImage,
        autofillSettings,
        uploadSpecimenMetadata,
        updateSpecimenFromMetadata,
        setMetadataErrors,
        selectedDevice,
        fetchPlateWells,
        updatePlateWells,
        traySide,
        specimenNum,
        lastSpecimenNum,
        algGenus,
        algSpecies,
        algSpeciesConfidence,
        algRegional,
        nrAlgGenus,
        nrAlgSpecies,
        nrAlgSpeciesConfidence,
        nrAlgRegional,
        plateAutoCompleteOptions,
        updateSpecimenAutoMetadata,
        // dispatch,
        uploadSpecimenBatchMetadata,
        updateSpecimenCaptureMetadata,
        wellAutocompleteOptions,
        // originalWell,
        // originalPlate,
        tagOptions,
        updateSpecimenTagOptions,
        specimenPosList,
        specimens,
        vectorType,
        hideFormErrors,
    } = props;


    const history = useHistory();
    const { sendRequest } = useWebServer();
    const location = useLocation();
    const { imageSrc, width, height, flippedSrc } = location.state || {};


    const handleImageSelect = (selectedImage) => {
        // Handle the selected image data received from AAA
        console.log('Selected Image:', selectedImage);
        // You can perform further actions with selectedImage here
    };

    // const handleClose = () => {
    //     history.goBack(); // Navigate back to the previous page
    // };

    const handleCloseModal = () => {
        toggleEnlargedSpecimenModal();
    };

    const [zoomControl, setZoomControl] = useState(1);
    const [zoomControl2, setZoomControl2] = useState(1);
    const [activeDrags, setActiveDrags] = useState(0);
    const [shouldProcess, setShouldProcess] = useState(true);
    const [shouldProcess2, setShouldProcess2] = useState(true);
    const [imageRotate, setImageRotate] = useState(0);
    const [imageRotate2, setImageRotate2] = useState(0);
    const [canImageProcess, setCanImageProcess] = useState(true);
    const [imageSize, setSize] = useState({ x: 700, y: 700 });
    const hdMatches = useMediaQuery((theme) => theme.breakpoints.up("hd"));
    const lgMatches = useMediaQuery((theme) => theme.breakpoints.up("lg"));
    const [maxWidthHeight, setMaxWidthHeight] = useState(700);
    const [minWidthHeight, setMinWidthHeight] = useState(700);

    useEffect(() => {
        let initMaxWidth = 700;
        if (hdMatches) {
            setSize({ x: 700, y: 700 });
            initMaxWidth = 700;
            setMinWidthHeight(700);
        }
        if (lgMatches) {
            setSize({ x: 700, y: 700 });
            initMaxWidth = 700;
            setMinWidthHeight(700);
        }
        setMaxWidthHeight(initMaxWidth);
    }, [hdMatches, lgMatches]);

    const trayh = height / 2;
    const trayw = width / 2;

    const specimenh = height / 2;
    const specimenw = width / 2;

    const handleNextSpecimen = () => {
        // if (Object.keys(form.errors).length === 0) 
        // {
        nextSpecimen();
        // }
    };

    const handlePreviousSpecimen = () => {
        // if (Object.keys(form.errors).length === 0) 
        // {
        prevSpecimen();
        // }
    };
    // useEffect(() => {        
    //     console.log("FI", frontImage, "BI", backImage);        
    // }, [handleNextSpecimen, handlePreviousSpecimen])


    const handleZoomIn = () => {
        setZoomControl(zoomControl + 0.02);
    };

    const handleZoomOut = () => {
        setZoomControl(zoomControl - 0.02);
    };

    const handleZoomIn2 = () => {
        setZoomControl2(zoomControl2 + 0.02);
    };

    const handleZoomOut2 = () => {
        setZoomControl2(zoomControl2 - 0.02);
    };

    const onStartDragButtonGroup = () => {
        setActiveDrags(activeDrags + 1);
    };

    const onStopDragButtonGroup = () => {
        setActiveDrags(activeDrags - 1);
    };

    const handleChangeShouldProcess = (event) => {
        setShouldProcess(!shouldProcess);
    };

    const handleChangeShouldProcess2 = (event) => {
        setShouldProcess2(!shouldProcess2);
    };

    const handleRotateImage = (i) => {
        setImageRotate((imageRotate + i + 360) % 360)
    }

    const handleRotateImage2 = (i) => {
        setImageRotate2((imageRotate2 + i + 360) % 360)
    }


    const processSpecimen = (image) => {
        const hist = getHistogram(image);
        percUpHardLowClip(image, hist);
        return image;
    };

    useEffect(() => {
        setImageRotate(0);
        setImageRotate2(0);
    }, [specimenId]);

    const numericalID = specimenId.split('_').pop();

    const [autofillTooltip, setAutofillTooltip] = useState(false);
    const [highLightSpecies, setHighLightSpecies] = useState(false);
    const [highLightSex, setHighLightSex] = useState(false);
    const [highLightPlateAndWell, setHighLightPlateAndWell] = useState(false);
    const [highLightLifeStage, setHighLightLifeStage] = useState(false);
    const [highLightGonotrophic, setHighLightGonotrophic] = useState(false);
    const [curSpe, setCurSpe] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [specimensArray, setSpecimensArray] = useState(specimens);
    const [metadata, setMetadata] = useState();
    const [loading, setLoading] = useState(true);
    const [updatingDisplayed, setUpdatingDisplayed] = useState(false);
    const [autofillModalOpen, setAutofillModalOpen] = useState(false);
    const [autofillProtectionDialog, setAutofillProtectionDialog] =
        useState(false);
    const [autofillProtectionData, setAutofillProtectionData] = useState([]);
    const [shouldAllowAutofill, setShouldAllowAutofill] = useState({
        shouldAllowAutofill: false,
        metadataFilled: false,
        autofillSettingsFilled: false,
    });
    const [wellPopup, setWellPopup] = useState(false);
    const [duplicateAutofillPopup, setDuplicateAutofillPopup] = useState(null);
    const [curTraySideForImage, setCurTraySideForImage] = useState(traySide);

    useEffect(() => {
        if (specimenId) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [specimenId]);

    useEffect(() => {
        setUpdatingDisplayed(false);
    }, [specimenNum]);

    useEffect(() => {
        const cur = specimensArray?.[specimenNum];
        const speciesChecked = autofillSettings?.speciesChecked;
        const sexChecked = autofillSettings?.sexChecked;
        const plateAndWellChecked = autofillSettings?.plateAndWellChecked;
        const lifeStageChecked =
            cur?.lifeStage && autofillSettings?.lifeStageChecked;
        const gonotrophicChecked =
            cur?.gonotrophic && autofillSettings?.gonotrophicChecked;

        const checkAllowAutofill = () => {
            const speciesFilled = cur?.genusSpecies && speciesChecked;
            const sexFilled = cur?.sex && sexChecked;
            const plateWellFilled =
                cur?.plate && plateAndWellChecked && cur?.well && plateAndWellChecked;
            const lifeStageFilled = cur?.lifeStage && lifeStageChecked;
            const gonotrophicFilled = cur?.gonotrophic && gonotrophicChecked;

            return {
                shouldAllowAutofill:
                    (speciesFilled ||
                        sexFilled ||
                        plateWellFilled ||
                        lifeStageFilled ||
                        gonotrophicFilled) &&
                    specimenNum !== lastSpecimenNum,
                metadataFilled:
                    cur?.genusSpecies ||
                    cur?.sex ||
                    (cur?.plate && cur?.well) ||
                    cur?.lifeStage ||
                    cur?.gonotrophic,
                autofillSettingsFilled:
                    speciesChecked ||
                    sexChecked ||
                    plateAndWellChecked ||
                    lifeStageChecked ||
                    gonotrophicChecked,
            };
        };

        setShouldAllowAutofill(checkAllowAutofill());
    }, [
        autofillSettings,
        specimenNum,
        lastSpecimenNum,
        specimenId,
        specimensArray,
    ]);

    const saveCurrentChange = async (value, difference, isSpecimenChanged) => {
        let newSpecimensValues = JSON.parse(JSON.stringify(specimensArray));
        newSpecimensValues[specimenNum] = JSON.parse(JSON.stringify(value));
        let result = JSON.parse(JSON.stringify(value));
        setSpecimensArray(newSpecimensValues);
        if (result?.genusSpecies || result?.genusSpecies === "") {
            const { genusSpecies, ...rest } = result;
            result = { ...rest, ...processGenusSpecies(genusSpecies) };
        }
        let tmpCaptureData = formatCaptureData(specimenId, result);
        await updateSpecimenCaptureMetadata({
            [specimenNum]: JSON.parse(JSON.stringify(tmpCaptureData)),
        });
        await updateSpecimenFromMetadata(
            trayId,
            selectedDevice,
            specimenNum,
            specimenId,
            result
        );

        if (specimenId === null) {
            return;
        }

        //parse genus species
        if (Object.keys(difference).includes("genusSpecies")) {
            difference = {
                ...difference,
                ...processGenusSpecies(difference?.["genusSpecies"] || ""),
            };
        }
        let batchMetadata = {
            [specimenId]: {
                humanId: _.pick(difference, [
                    "genus",
                    "species",
                    "sex",
                    "gonotrophic",
                    "lifeStage",
                ]),
                molecularId: _.pick(difference, ["plate", "well"]),
                specimenId: specimenId,
                institutionId: tmpCaptureData?.institutionId,
                tags: result.tags,
            },
        };

        //upload to the server
        if (!isSpecimenChanged) {
            await uploadSpecimenBatchMetadata(trayId, { batchMetadata });
        }
        //console.log(batchMetadata);
    };


    const handleAutofill = async (form) => {
        //find out what is the current value that will be autofill and the autofill setting must be true
        if (!(Object.keys(form.errors)?.length === 0)) {
            return;
        }
        let indexArray = [...Array(lastSpecimenNum - specimenNum).keys()].map(
            (i) => i + specimenNum + 1
        );
        const speciesChecked = autofillSettings?.speciesChecked;
        const sexChecked = autofillSettings?.sexChecked;
        const plateAndWellChecked = autofillSettings?.plateAndWellChecked;
        const lifeStageChecked = autofillSettings?.lifeStageChecked;
        const gonotrophicChecked = autofillSettings?.gonotrophicChecked;

        let filledSpecimens = [];

        for (let i of indexArray) {
            const curGenus = specimensArray?.[i]?.genusSpecies;
            const curSex = specimensArray?.[i]?.sex;
            const curPlate = specimensArray?.[i]?.plate;
            const curWell = specimensArray?.[i]?.well;
            const curLifeStage = specimensArray?.[i]?.lifeStage;
            const curGonotrophic = specimensArray?.[i]?.gonotrophic;

            let filledFields = {};
            if (speciesChecked && curGenus && curGenus?.length !== 0) {
                filledFields = { ...filledFields, genusSpecies: curGenus };
            }
            if (sexChecked && curSex && curSex?.length !== 0) {
                filledFields = { ...filledFields, sex: curSex };
            }
            if (
                plateAndWellChecked &&
                ((curPlate && curPlate?.length !== 0) ||
                    (curWell && curWell?.length !== 0))
            ) {
                filledFields = { ...filledFields, plate: curPlate, well: curWell };
            }

            if (lifeStageChecked && curLifeStage && curLifeStage?.length !== 0) {
                filledFields = { ...filledFields, lifeStage: curLifeStage };
            }

            if (
                gonotrophicChecked &&
                curGonotrophic &&
                curGonotrophic?.length !== 0
            ) {
                filledFields = { ...filledFields, gonotrophic: curGonotrophic };
            }

            if (Object.keys(filledFields).length > 0) {
                filledSpecimens.push({ [i]: filledFields });
            }
        }

        // //check duplicate well in auto fill
        setAutofillProtectionData(filledSpecimens);
        if (filledSpecimens.length > 0) {
            setAutofillProtectionDialog(true);
        } else {
            let duplicateWells = [];
            if (
                specimensArray?.[specimenNum]?.plate &&
                specimensArray?.[specimenNum]?.well &&
                autofillSettings?.plateAndWellChecked
            ) {
                duplicateWells = getDuplicateWells(
                    specimensArray?.[specimenNum]?.well,
                    indexArray.length,
                    wellAutocompleteOptions
                );
            }
            if (duplicateWells.length > 0) {
                setDuplicateAutofillPopup({
                    kwargs: {
                        overwriteChoice: OverwriteOptionsEnum.overwrite,
                        lastSpecimenNum,
                        duplicateWells,
                        autocompleteOptions: wellAutocompleteOptions,
                    },
                    skipOptions: [],
                });
            } else {
                //start auto fill
                submitAutofillData();
            }
        }
    };

    const submitAutofillData = async (params = {}) => {
        const {
            duplicateWellsChoice = DuplicateOptionsEnum.overwrite,
            overwriteChoice = OverwriteOptionsEnum.overwrite,
            lastAutofillIdx = -1,
            autocompleteOptions = null,
        } = params;

        await specimenAutoFillDataSubmit(
            specimensArray,
            specimenNum,
            autofillSettings,
            trayId,
            selectedDevice,
            updateSpecimenAutoMetadata,
            uploadSpecimenBatchMetadata,
            handleFieldUpdate,
            updateSpecimenCaptureMetadata,
            autocompleteOptions ? autocompleteOptions : wellAutocompleteOptions,
            setMetadata,
            duplicateWellsChoice,
            overwriteChoice,
            lastAutofillIdx === -1 ? lastSpecimenNum - specimenNum : lastAutofillIdx,
            specimenPosList,
            setSpecimensArray
        );
    };

    const loadingAlgResults =
        !(algGenus || algGenus === "") &&
        !(algSpecies || algSpecies === "") &&
        !(algSpeciesConfidence || algSpeciesConfidence === 0);
    const onBlurGenusSpecies = (value, form) => {
        if (!!value && value !== "0") {
            let newValue = _.chain(value).trim().upperFirst().value();
            if (!newValue.includes(" ")) newValue = `${newValue} spp`;
            form.form.mutators.setValue("genusSpecies", newValue);
        }
    };

    const onBlurWell = (value, form) => {
        const newValue =
            value.length === 2 ? value.charAt(0) + "0" + value.charAt(1) : value;
        form.form.mutators.setValue("well", newValue);
    };

    const onBlurPlate = (value, form) => {
        fetchPlateWells(value, sendRequest);
    };

    const normalizeGenusSpecies = (value) => _.upperFirst(value);

    const normalizeWell = (value) => {
        value = _.chain(value)
            .upperFirst()
            .truncate({ length: 3, omission: "" })
            .value();
        if (value.length === 2) {
            const i1 = parseInt(value.charAt(1));
            if (!(i1 || i1 === 0)) {
                return value.charAt(0);
            }
            if (i1 > 1) {
                return value.charAt(0) + "0" + value.charAt(1);
            }
        } else {
            const num = parseInt(value.slice(1));
            if (!num || num > 12) {
                return value.slice(0, 2);
            }
        }
        return value;
    };

    const calcAndSetDuplicateAutofillPopupOpts = (overwriteChoice, kwargs) => {
        // See if we need to go to duplicate message

        let fillWell = curSpe?.well;
        const lastAutofillIdx =
            overwriteChoice === OverwriteOptionsEnum.autofill
                ? getAutofillOverwriteCount(
                    autofillProtectionData,
                    specimenId,
                    specimenNum
                ) - specimenNum
                : lastSpecimenNum - specimenNum;
        let autocompleteOptions = null;
        if (fillWell && autofillSettings.plateAndWellChecked) {
            autocompleteOptions = getAutocompleteOptions(
                overwriteChoice,
                wellAutocompleteOptions,
                specimensArray,
                specimenNum,
                lastAutofillIdx
            );
            const duplicateWells = getDuplicateWells(
                specimensArray?.[specimenNum]?.well,
                lastSpecimenNum - specimenNum,
                autocompleteOptions
            );
            if (duplicateWells.length > 0) {
                setDuplicateAutofillPopup({
                    kwargs: {
                        overwriteChoice,
                        lastAutofillIdx,
                        duplicateWells,
                        autocompleteOptions,
                        ...kwargs,
                    },
                    skipOptions: [],
                });
                return;
            }
        }
        // if we're skipping the duplicate options, then we need to go to the next step
        submitAutofillData({
            duplicateWellsChoice: DuplicateOptionsEnum.none,
            overwriteChoice,
            lastAutofillIdx,
            autocompleteOptions,
            ...kwargs,
        });
    };

    useEffect(() => {
        if (!initialized) {
            setSpecimensArray(specimens);
            setInitialized(true);
        }
        if (
            Array.isArray(specimensArray) &&
            Array.isArray(specimens) &&
            specimensArray.length === specimens.length
        ) {
            for (let i = 0; i < specimensArray?.length; i++) {
                let difference = diff(specimensArray[i], specimens[i]);
                if (difference?.["id"]) {
                    setSpecimensArray(specimens);
                    break;
                }
            }
        }
    }, [specimenNum, specimens, initialized, specimensArray]);

    useEffect(() => {
        setInitialized(false);
    }, [specimenId]);

    useEffect(() => {
        setCurSpe(specimensArray?.[specimenNum]);
    }, [specimensArray, specimenNum]);

    const handleFieldUpdate = (updatedMetaData) => {
        // dispatch(initialize("SpecimenCapture", updatedMetaData));
    };

    const clearToolTip = () => {
        setAutofillTooltip(false);
        setHighLightSpecies(false);
        setHighLightSex(false);
        setHighLightPlateAndWell(false);
        setHighLightLifeStage(false);
        setHighLightGonotrophic(false);
    };
    const save = async (values, difference) => {
        let isSpecimenChanged = difference.id ? true : false;
        //saveCurrentChange({ ...values }, isSpecimenChanged)
        saveCurrentChange(values, difference, isSpecimenChanged);
    };

    const handleSpecimenJump = (i) => {
        jumpSpecimen(i);
    };


    useEffect(() => {
        setHighLightSpecies(
            autofillTooltip &
            autofillSettings?.speciesChecked &
            !(curSpe?.genusSpecies?.length > 0)
        );
        setHighLightPlateAndWell(
            autofillTooltip &
            autofillSettings?.plateAndWellChecked &
            !(
                (curSpe?.plate?.length > 0) &
                (curSpe?.well?.length > 0)
            )
        );
        setHighLightSex(
            autofillTooltip &
            autofillSettings?.sexChecked &
            !(curSpe?.sex?.length > 0)
        );
        setHighLightLifeStage(
            autofillTooltip &
            autofillSettings?.lifeStageChecked &
            !(curSpe?.lifeStage?.length > 0)
        );
        setHighLightGonotrophic(
            autofillTooltip &
            autofillSettings?.gonotrophicChecked &
            !(curSpe?.gonotrophic?.length > 0)
        );
    }, [autofillTooltip, autofillSettings, curSpe]);


    const computeUnknownList = () => {
        let unknownList = [];
        const inRegionName = getSppDisplayName(algGenus, algSpecies);
        const inRegionConf = Math.round(algSpeciesConfidence * 100);
        if (inRegionName && inRegionConf) {
            unknownList.push({
                name: inRegionName,
                genus: algGenus,
                species: algSpecies,
                confidence: inRegionConf,
                region:
                    algRegional && nrAlgRegional
                        ? ""
                        : algRegional
                            ? " (in region)"
                            : " (out of region)",
            });
        }

        if (nrAlgGenus && nrAlgSpecies && nrAlgGenus !== "") {
            const nrName = getSppDisplayName(nrAlgGenus, nrAlgSpecies);
            const nrConf = Math.round(nrAlgSpeciesConfidence * 100);
            if (nrName && nrConf) {
                unknownList.push({
                    name: nrName,
                    genus: nrAlgGenus,
                    species: nrAlgSpecies,
                    confidence: nrConf,
                    region:
                        algRegional && nrAlgRegional
                            ? ""
                            : nrAlgRegional
                                ? " (in region)"
                                : " (out of region)",
                });
            }
        }
        return unknownList
            .sort((a, b) => b.confidence - a.confidence)
            .map((cur, index) => {
                return (
                    <Typography
                        sx={smallerSpecimenSx}
                        key={index}
                        onClick={(e) => {
                            saveCurrentChange(
                                { ...curSpe, genusSpecies: cur.name },
                                { genusSpecies: cur.name },
                                false
                            );
                        }}
                    >
                        <em>{capitalize(cur.name)}</em> - {`${cur.confidence}%`}
                        {cur.region}
                    </Typography>
                );
            });
    };

    return (
        <div >
            <div style={{ marginLeft: '50px', color: "#3f51b5", fontWeight: 'bold' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" mt={4}>
                    <Grid item xs={6} sm={6} >
                        <div
                            style={{ fontSize: '30px', fontFamily: "Roboto", display: 'flex', justifyContent: 'center', color: "#3f51b5", marginBottom: '15px' }}>
                            TRAY ID:{trayId} &#160;&#160;&#160;
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} >
                        <div
                            style={{ fontSize: '30px', fontFamily: "Roboto", display: 'flex', justifyContent: 'center', color: "#3f51b5", marginBottom: '15px' }}>
                            SPECIMEN ID:{numericalID}
                        </div>
                    </Grid>
                    <Grid item marginLeft='auto' sx={{
                        display: 'flex', justifyContent: 'flex-end',
                        marginRight: "5rem"
                    }}><Button onClick={handleCloseModal} color="primary" variant="contained" >CLOSE</Button></Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="center" alignItems="center" mt={4}>
                    <Grid item>
                        <Button
                            onClick={handlePreviousSpecimen}
                            id={"previous-specimen"}
                        // disabled={updatingDisplayed}
                        >
                            <ArrowBackIosIcon sx={{
                                width: '50px',
                                height: '50px'
                            }}
                            />
                        </Button>
                    </Grid>

                    <Grid item>

                        <div style={{ marginRight: '50px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}> FRONT</div>
                            <Box className={"flex flex-col relative"}>
                                <Draggable
                                    bounds="parent"
                                    grid={[10, 10]}
                                    handle="strong"
                                    onStart={onStartDragButtonGroup}
                                    onStop={onStopDragButtonGroup}
                                >
                                    <ButtonGroup
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            position: "absolute",
                                            marginTop: "6px",
                                            marginLeft: "6px",
                                        }}
                                    >
                                        <strong style={{ cursor: "move" }}>
                                            <DragIndicatorSharpIcon
                                                style={{
                                                    color: "rgb(66, 139, 202)",
                                                    transform: "rotate(90deg)",
                                                    width: "80%",
                                                    marginBottom: "8px",
                                                }}
                                            />
                                        </strong>
                                        <Tooltip title={`Zoom In`} aria-label="zoom-in-tooltip">
                                            <IconButton
                                                aria-label="zoom-in-button"
                                                component="span"
                                                onClick={handleZoomIn}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <ZoomIn style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`Zoom Out`} aria-label="zoom-out-tooltip">
                                            <IconButton
                                                size="small"
                                                aria-label="zoom-out-button"
                                                component="span"
                                                onClick={handleZoomOut}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <ZoomOut style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`Rotate -90°`} aria-label="Rotate--90°-tooltip">
                                            <IconButton
                                                size="small"
                                                aria-label="rotate-90-button"
                                                component="span"
                                                onClick={() => { handleRotateImage(-90) }}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <Rotate90DegreesCcwIcon style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`Rotate 90°`} aria-label="Rotate-90°-tooltip">
                                            <IconButton
                                                size="small"
                                                aria-label="rotate-90-button"
                                                component="span"
                                                onClick={() => { handleRotateImage(90) }}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <Rotate90DegreesCwIcon style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title={`Turn image enhancement ${shouldProcess ? "off" : "on"}`}
                                            aria-label="image-enhancement-tooltip"
                                        >
                                            <IconButton
                                                size="small"
                                                aria-label="image-enhancement-toggle"
                                                component="span"
                                                onClick={handleChangeShouldProcess}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                {shouldProcess ? (
                                                    <Star style={{ fontSize: 40, color: "gold" }} />
                                                ) : (
                                                    <StarOutline style={{ fontSize: 40, color: "gold" }} />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Draggable>
                                <Box
                                    sx={{
                                        height: imageSize.y,
                                        width: imageSize.x,
                                        border: "solid 2px black",
                                        maxWidth: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}

                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    {frontImage ? (
                                        < ProcessedImage
                                            src={frontImage}
                                            alt={"No image available"}
                                            draw={processSpecimen}
                                            shouldProcess={shouldProcess}
                                            zoom={zoomControl}
                                            rotate={imageRotate}
                                            width={imageSize.x}
                                            height={imageSize.y}
                                            canImageProcess={canImageProcess}
                                        />
                                    ) : (<div style={{
                                        color: "#3f51b5",
                                        fontSize: '20px',
                                        textAlign: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                        width: "100%",
                                        height: "100%"
                                    }}>No image found</div>)}

                                </Box>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item>

                        <div style={{}}>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}> BACK</div>
                            <Box className={"flex flex-col relative"}>
                                <Draggable
                                    bounds="parent"
                                    grid={[10, 10]}
                                    handle="strong"
                                    onStart={onStartDragButtonGroup}
                                    onStop={onStopDragButtonGroup}
                                    style={{
                                        marginTop: "10px"
                                    }}
                                >
                                    <ButtonGroup
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            position: "absolute",
                                            marginTop: "6px",
                                            marginLeft: "6px",
                                        }}
                                    >
                                        <strong style={{ cursor: "move" }}>
                                            <DragIndicatorSharpIcon
                                                style={{
                                                    color: "rgb(66, 139, 202)",
                                                    transform: "rotate(90deg)",
                                                    width: "80%",
                                                    marginBottom: "8px",
                                                }}
                                            />
                                        </strong>
                                        <Tooltip title={`Zoom In`} aria-label="zoom-in-tooltip">
                                            <IconButton
                                                size="small"
                                                aria-label="zoom-in-button"
                                                component="span"
                                                onClick={handleZoomIn2}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <ZoomIn style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`Zoom Out`} aria-label="zoom-out-tooltip">
                                            <IconButton
                                                size="small"
                                                aria-label="zoom-out-button"
                                                component="span"
                                                onClick={handleZoomOut2}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <ZoomOut style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`Rotate -90°`} aria-label="Rotate--90°-tooltip">
                                            <IconButton
                                                size="small"
                                                aria-label="rotate-90-button"
                                                component="span"
                                                onClick={() => { handleRotateImage2(-90) }}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <Rotate90DegreesCcwIcon style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`Rotate 90°`} aria-label="Rotate-90°-tooltip">
                                            <IconButton
                                                size="small"
                                                aria-label="rotate-90-button"
                                                component="span"
                                                onClick={() => { handleRotateImage2(90) }}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                <Rotate90DegreesCwIcon style={{ fontSize: 40, color: "rgb(66, 139, 202)" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title={`Turn image enhancement ${shouldProcess2 ? "off" : "on"}`}
                                            aria-label="image-enhancement-tooltip"
                                        >
                                            <IconButton
                                                size="small"
                                                aria-label="image-enhancement-toggle"
                                                component="span"
                                                onClick={handleChangeShouldProcess2}
                                                style={{ marginBottom: "8px" }}
                                            >
                                                {shouldProcess2 ? (
                                                    <Star style={{ fontSize: 40, color: "gold" }} />
                                                ) : (
                                                    <StarOutline style={{ fontSize: 40, color: "gold" }} />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Draggable>
                                <Box
                                    sx={{
                                        height: imageSize.y,
                                        width: imageSize.x,
                                        border: "solid 2px black",
                                        maxWidth: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    {backImage ? (
                                        < ProcessedImage
                                            src={backImage}
                                            alt={"No image available"}
                                            draw={processSpecimen}
                                            shouldProcess={shouldProcess2}
                                            zoom={zoomControl2}
                                            rotate={imageRotate2}
                                            width={imageSize.x}
                                            height={imageSize.y}
                                            canImageProcess={canImageProcess}
                                        />
                                    ) : (<div style={{
                                        color: "#3f51b5",
                                        fontSize: '20px',
                                        textAlign: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                        width: "100%",
                                        height: "100%"
                                    }}>No image found</div>)}

                                </Box>
                            </Box>
                        </div>
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={handleNextSpecimen}
                            id={"next-specimen"}
                        // disabled={updatingDisplayed}

                        >
                            <ArrowForwardIosIcon
                                sx={{
                                    width: '50px',
                                    height: '50px'
                                }}
                            />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Form
                            onSubmit={() => { }}
                            mutators={{
                                setSex: (args, state, utils) => {
                                    utils.changeValue(state, "sex", () => "");
                                },
                                setValue: ([field, value], state, { changeValue }) => {
                                    changeValue(state, field, () => value);
                                },
                            }}
                            initialValues={curSpe}
                            validate={(values) => {
                                const errors = {};
                                if (
                                    values.well &&
                                    values.well?.length > 0 &&
                                    (values.plate?.length <= 0 || !values.plate)
                                ) {
                                    errors.plate = "Must have a plate number";
                                }
                                if (
                                    values.plate &&
                                    values.plate?.length > 0 &&
                                    (values.well?.length <= 0 || !values.well)
                                ) {
                                    errors.well = "Must have a well number";
                                }
                                return errors;
                            }} render={(form, change) => (
                                <div id={"specimen-details"}>
                                    <AutoSave debounce={300} save={save} form={form} />

                                    <Box

                                    >
                                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', fontSize: '20px' }}> CONTRIBUTED DATA </div>
                                        <div>
                                            {/* <Tooltip
                                                title={
                                                    <div>
                                                        <Typography variant="subtitle1">
                                                            {!shouldAllowAutofill?.autofillSettingsFilled &&
                                                                "Update autofill settings before autofilling\n"}
                                                        </Typography>
                                                        <Typography variant="subtitle1">
                                                            {!shouldAllowAutofill?.metadataFilled &&
                                                                shouldAllowAutofill?.autofillSettingsFilled &&
                                                                "Enter data for current specimen before autofilling or filling with empty data\n"}
                                                        </Typography>
                                                        <Typography variant="subtitle1">
                                                            {shouldAllowAutofill.metadataFilled &&
                                                                shouldAllowAutofill.autofillSettingsFilled &&
                                                                "Enter data for at least one of the selected fields before autofilling or filling with empty data\n"}
                                                        </Typography>
                                                    </div>
                                                }
                                                onOpen={() => setAutofillTooltip(true)}
                                                open={
                                                    !shouldAllowAutofill?.shouldAllowAutofill && autofillTooltip
                                                }
                                                onClose={() => clearToolTip()}
                                            >
                                                <span>
                                                    <ResponsiveButton
                                                        // className={classes.autofillButton}
                                                        sx={buttonStyles.autofillButton}
                                                        // disabled={!shouldAllowAutofill?.shouldAllowAutofill}
                                                        variant={"outlined"}
                                                        onClick={() => handleAutofill(form)}
                                                    >
                                                        Autofill{" "}
                                                        {specimenNum
                                                            ? `${specimenNum + 1}-${lastSpecimenNum + 1}`
                                                            : `1-12`}
                                                    </ResponsiveButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={<Typography>&darr;</Typography>}
                                                placement="top"
                                                open={
                                                    !shouldAllowAutofill?.shouldAllowAutofill &&
                                                    autofillTooltip &&
                                                    !shouldAllowAutofill?.autofillSettingsFilled
                                                }
                                            >
                                                <ResponsiveIconButton>
                                                    <SettingsIcon
                                                        // className={classes.infoButton}
                                                        sx={buttonStyles.infoButton}
                                                        onClick={() => setAutofillModalOpen(true)}
                                                    />
                                                </ResponsiveIconButton>
                                            </Tooltip> */}
                                            {/* <AutofillSettingModal
                                                autofillModalOpen={autofillModalOpen}
                                                setAutofillModalOpen={setAutofillModalOpen}
                                                sx={{ zIndex: 8000, }}
                                            /> */}
                                        </div>
                                        <form id={"specimen-details-form"}>
                                            {/* <Grid
                                                container
                                                sx={{
                                                    px: ["1rem", "1rem", "1rem", "1rem", "2rem", "2rem"],
                                                    pt: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "1rem", "1rem"],
                                                }}
                                                spacing={2}
                                            > */}

                                            <Field
                                                label="Species"
                                                name={`genusSpecies`}
                                                inputProps={{
                                                    id: `genus-species`,
                                                }}
                                                hideFormErrors={hideFormErrors}
                                                normalize={normalizeGenusSpecies}
                                                component={renderAutocomplete}
                                                options={
                                                    genusSpeciesAutoCompleteOptions?.[vectorType || "mosquito"]
                                                }
                                                autocompleteprops={{
                                                    getOptionLabel: (option) => option,
                                                    isOptionEqualToValue: (option, value) =>
                                                        option.toLowerCase() === value?.toLowerCase(),
                                                }}
                                                // onChange={(event, value) => setGenusSpecies(value)}
                                                onBlur={(event, value) => onBlurGenusSpecies(value, form)}
                                                helperText="Taxonomist determined ID"
                                                multilineFlag={true}
                                                validate={checkCommaAndBackSlash}
                                            />


                                            <SexInput
                                                hideFormErrors={hideFormErrors}
                                                form={form}
                                                change={change}
                                            />


                                            <Field
                                                label="Life Stage"
                                                name={`lifeStage`}
                                                inputProps={{ id: `life-stage-field` }}
                                                component={renderAutocomplete}
                                                options={lifeStatusAutoCompleteOptions}
                                                helperText="Life Stage"
                                                validate={checkCommaAndBackSlash}
                                            />


                                            <Field
                                                label="gonotrophic"
                                                name={`gonotrophic`}
                                                inputProps={{ id: `gonotrophic-field` }}
                                                component={renderAutocomplete}
                                                options={gonotrophicAutoCompleteOptions}
                                                helperText="Feeding Status"
                                                validate={checkCommaAndBackSlash}
                                            />


                                            <Field
                                                label="Unique Plate Number"
                                                name={`plate`}
                                                inputProps={{ id: `plate-field` }}
                                                hideFormErrors={hideFormErrors}
                                                component={renderAutocomplete}
                                                options={plateAutoCompleteOptions}
                                                onBlur={(event) => onBlurPlate(event.target.value, form)}
                                                helperText="Molecular ID Plate"
                                                validate={checkCommaAndBackSlash}
                                            />


                                            <Field
                                                label="Well Number"
                                                name={`well`}
                                                inputProps={{ id: `well-field` }}
                                                normalize={normalizeWell}
                                                hideFormErrors={hideFormErrors}
                                                component={renderAutocomplete}
                                                options={wellAutocompleteOptions}
                                                onBlur={(event, value) => {
                                                    onBlurWell(event.target.value, form);
                                                }}
                                                helperText="Molecular ID Well( A1 - H12)"
                                                validate={checkWellNumber}
                                            />


                                            <Field
                                                multi={"true"}
                                                label="Specimen Tags"
                                                name={`tags`}
                                                inputProps={{ id: `tags` }}
                                                hideFormErrors={hideFormErrors}
                                                component={RenderAutocompleteMultiple}
                                                fieldValue={form?.values?.tags || []}
                                                options={tagOptions || []}
                                                onChange={(event, value) => {
                                                    form.form.mutators.setValue("tags", [...value]);
                                                }}
                                                onBlur={(e) => e.preventDefault()}
                                                multiple={true}
                                                validate={checkCommaAndBackSlashOnTags}
                                            />

                                            <Grid item  >
                                                <HiddenField
                                                    text={_.capitalize(
                                                        curSpe?.genusSpecies?.toLowerCase()
                                                    )}
                                                    Name="Species: "
                                                    display={
                                                        curSpe?.genusSpecies?.length > 0 &&
                                                        !autofillSettings?.speciesDisplay
                                                    }
                                                    isItalic={true}
                                                />
                                                <HiddenField
                                                    text={curSpe?.sex}
                                                    Name="Sex: "
                                                    display={
                                                        curSpe?.sex?.length > 0 &&
                                                        !autofillSettings?.sexDisplay
                                                    }
                                                />
                                                <HiddenField
                                                    text={curSpe?.lifeStage}
                                                    Name="Life Stage: "
                                                    display={
                                                        curSpe?.lifeStage?.length > 0 &&
                                                        !autofillSettings?.lifeStageDisplay
                                                    }
                                                />
                                                <HiddenField
                                                    text={curSpe?.gonotrophic}
                                                    Name="Gonotrophic: "
                                                    display={
                                                        curSpe?.gonotrophic?.length > 0 &&
                                                        !autofillSettings?.gonotrophicDisplay
                                                    }
                                                />
                                                <HiddenField
                                                    text={curSpe?.plate}
                                                    Name="Plate: "
                                                    display={
                                                        !autofillSettings?.molecularDisplay &&
                                                        curSpe?.plate &&
                                                        curSpe?.well
                                                    }
                                                />
                                                <HiddenField
                                                    text={curSpe?.well}
                                                    Name="Well: "
                                                    display={
                                                        !autofillSettings?.molecularDisplay &&
                                                        curSpe?.plate &&
                                                        curSpe?.well
                                                    }
                                                />
                                            </Grid>
                                            {/* </Grid> */}

                                        </form>


                                    </Box>
                                </div>

                            )}
                        />


                    </Grid>
                </Grid >

                {/* <Box marginTop={"30px"} display="flex" flexDirection="row" justifyContent="center" sx={{ fontSize: '20px' }} >
                    <div>
                        <div style={{ color: "#3f51b5", display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}> TRAY IMAGE </div>
                        {trayImage ? (
                            <img src={trayImage} alt={"trayimg"} width={'300px'} height={'300px'} />
                        ) : (
                            (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: trayh, width, border: '0.5px solid #3f51b5' }}>No image found</div>)
                        )}                        
                    </div>

                </Box> */}
            </div >


        </div >


    )
}

// export default EnlargedSpecimen;
const scaleBbox = (bbox) => {
    if (Array.isArray(bbox) && bbox.length === 4) {
        const [x1, y1, x2, y2] = bbox;
        return [x1 / rx, y1 / ry, x2 / rx, y2 / ry];
    }
};

const parseEmptySpp = (spp) => {
    // console.log(JSON.stringify(spp, null, 4));
    const algEmpty = spp?.empty;
    if (algEmpty) {
        if (algEmpty === "dirty") {
            return {
                ...spp,
                bboxGenus: "dirty",
                bboxSpecies: "cell",
            };
        } else if (algEmpty === "empty") {
            return {
                ...spp,
                bboxGenus: "empty",
                bboxSpecies: "cell",
            };
        }
    }
    return {
        bboxGenus: spp?.genus,
        bboxSpecies: spp?.species,
    };
};

export const mapStateToProps = (state) => {
    const { selectedDevice } = state.devices;
    console.log("selected device", selectedDevice);
    if (!selectedDevice) return { selectedDevice };
    let {
        [selectedDevice]: { id: toolId, vectorType },
    } = state.devices;
    if (!vectorType) {
        let curTrayDevice = state.capture?.tray?.device;
        vectorType = state.devices?.[curTrayDevice]?.vectorType;
    }
    const { tray, traySide, specimenOnDisplay, capturing, disableCapturing } =
        state.capture;

    console.log("capture state", state.capture);

    const lastSpecimenNum = Object.keys(tray?.specimens || {}).length - 1;

    const autofillSettings = {
        speciesChecked: state.user.autofillSettings?.species,
        sexChecked: state.user.autofillSettings?.sex,
        plateAndWellChecked: state.user.autofillSettings?.molecular,
        lifeStageChecked: state.user.autofillSettings?.lifeStage,
        gonotrophicChecked: state.user.autofillSettings?.gonotrophic,
        ...state.user.autofillSettings,
    };
    console.log("specimenondisplay", specimenOnDisplay);
    if (tray?.specimens?.[specimenOnDisplay]) {
        const {
            id: trayId,
            specimens: {
                [specimenOnDisplay]: {
                    id: specimenId,
                    position: specimenNum,
                    defaultInstitutionId,
                },
            },
            specimens2: specList,
            [`${traySide}Img`]: trayImage,
            [`bboxes${capitalize(traySide)}`]: bboxes,
        } = tray;
        const frontImage =
            tray.specimens?.[specimenOnDisplay]?.front?.imgPath || "";
        const backImage =
            tray.specimens?.[specimenOnDisplay]?.back?.imgPath || "";


        let specimens2 = traySide
            ? Object.values(specList || {}).map((specimen) => {
                let algorithmId = specimen?.[traySide]?.algorithmId;
                let genusSpecies = parseEmptySpp(specimen?.[traySide]?.algorithmId);
                let algRegional =
                    specimen?.[traySide]?.algorithmId?.regional === undefined
                        ? true
                        : specimen?.[traySide]?.algorithmId?.regional;
                if (
                    !(
                        algorithmId?.empty === "empty" || algorithmId?.empty === "dirty"
                    ) &&
                    (algorithmId?.speciesConfidence < userAlgConfidence || !algRegional)
                ) {
                    genusSpecies["bboxGenus"] = "Unknown";
                    genusSpecies["bboxSpecies"] = "Spp";
                }
                return {
                    ..._.omit(specimen, ["front", "back"]),
                    imgPath: specimen?.[traySide]?.imgPath,
                    bbox: scaleBbox(
                        Array.isArray(bboxes) &&
                            bboxes.length > 0 &&
                            (specimen?.num || specimen?.num === 0)
                            ? bboxes[specimen.num]
                            : specimen?.[traySide]?.bbox
                    ),
                    ...(specimen?.[traySide]?.algorithmId ? genusSpecies : {}),
                };
            })
            : null;
        let algGenus =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.genus;
        let algSpecies =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.species;
        let algSpeciesConfidence =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId
                ?.speciesConfidence;
        let algRegional =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId
                ?.regional === undefined
                ? true
                : tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId
                    ?.regional;
        let nrAlgGenus =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2?.genus;
        let nrAlgSpecies =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2?.species;
        let nrAlgSpeciesConfidence =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2
                ?.speciesConfidence;
        let nrAlgRegional =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2
                ?.regional === undefined
                ? true
                : tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2
                    ?.regional;
        let algSex =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.sex;
        if (!algSex && algSex !== "") {
            algSex = undefined;
        }

        let algEmpty =
            tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.empty;
        if (!algEmpty && algEmpty !== "") {
            algEmpty = undefined;
        }

        if (algEmpty) {
            if (algEmpty === "dirty" || algEmpty === "Dirty") {
                algGenus = "dirty";
                algSpecies = "cell";
            } else if (algEmpty === "empty" || algEmpty === "Empty") {
                algGenus = "empty";
                algSpecies = "cell";
            }
        }

        let initialValues = state?.form?.SpecimenCapture?.values;

        const specimens = Object.values(tray?.specimens).map(
            ({ humanId, molecularId, institutionId, tags, ...cur }) => {
                return {
                    genusSpecies:
                        humanId?.genus && humanId?.species
                            ? `${humanId?.genus} ${humanId?.species}`
                            : undefined,
                    institutionId: institutionId,
                    plate: molecularId?.plate,
                    well: molecularId?.well,
                    tags: tags,
                    sex: humanId?.sex,
                    gonotrophic: humanId?.gonotrophic,
                    lifeStage: humanId?.lifeStage,
                    id: cur?.id,
                    empty:
                        ["empty", "dirty"].includes(cur?.front?.algorithmId?.empty) ||
                        ["empty", "dirty"].includes(cur?.back?.algorithmId?.empty),
                };
            }
        );

        const specimenPosList = Object.values(tray?.specimens)?.reduce(
            (values, { id, humanId, molecularId, institutionId, position }, i) => {
                values[id] = position;
                return values;
            },
            {}
        );

        const { isEnlargedSpecimenModalOpen } = state.enlargedspecimenR;

        const { displayMetadataErrors } = state.settings;
        const { plateWells = {} } = state.organization;
        const contributedPlates = Object.keys(
            _.pickBy(plateWells, (plate, wells) => wells.length > 0)
        );
        const currentPlate = specimens?.[specimenNum]?.["plate"];
        let wellAutocompleteOptions = DefaultWellAutocompleteOptions;
        if (currentPlate) {
            wellAutocompleteOptions = filterSortedList(
                DefaultWellAutocompleteOptions,
                plateWells[currentPlate] || []
            );
        }
        // if viewing tray, allow the original well for this specimen
        const originalWell = tray?.specimens?.[specimenOnDisplay]?.molecularId?.well
            ? tray?.specimens?.[specimenOnDisplay]?.molecularId?.well
            : null;
        const originalPlate = tray?.specimens?.[specimenOnDisplay]?.molecularId
            ?.plate
            ? tray?.specimens?.[specimenOnDisplay]?.molecularId?.plate
            : null;
        if (
            originalWell &&
            originalPlate &&
            currentPlate &&
            currentPlate === originalPlate
        ) {
            wellAutocompleteOptions = insertSortedSet(
                wellAutocompleteOptions,
                originalWell
            );
        }

        const tagOptions = state?.organization?.tags || [];
        let userAlgConfidence =
            state?.user?.algConfidence || state?.user?.algConfidence === 0
                ? state.user.algConfidence / 100
                : 0;

        return {
            selectedDevice,
            frontImage,
            backImage,
            traySide,
            capturing,
            disableCapturing,
            specimenNum,
            lastSpecimenNum,
            algGenus,
            algSpecies,
            algSpeciesConfidence,
            algRegional,
            algSex,
            nrAlgGenus,
            nrAlgSpecies,
            nrAlgSpeciesConfidence,
            nrAlgRegional,
            specimenId,
            defaultInstitutionId,
            trayId,
            initialValues,
            displayMetadataErrors,
            plateAutoCompleteOptions: contributedPlates.map((x) => `${x}`),
            wellAutocompleteOptions,
            originalWell,
            originalPlate,
            autofillSettings,
            tagOptions,
            specimenPosList,
            specimens,
            specimens2,
            userAlgConfidence,
            vectorType,
            isEnlargedSpecimenModalOpen,
            trayImage
        };
    }

    return { selectedDevice, toolId, autofillSettings };
};

export default connect(mapStateToProps, {
    // submit,
    uploadSpecimenMetadata,
    uploadSpecimenBatchMetadata,
    updateSpecimenFromMetadata,
    setMetadataErrors,
    nextSpecimen,
    prevSpecimen,
    toggleEnlargedSpecimenModal,
    updateSpecimenAutoMetadata,
    updateSpecimenCaptureMetadata,
    fetchPlateWells,
    updatePlateWells,
    updateSpecimenTagOptions,
})(EnlargedSpecimen);