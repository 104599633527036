import React, { useState, useEffect } from 'react'
import { Button } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { useHistory } from "react-router-dom";
import ROUTES from "../../../AppRouter/routes";
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useWebServer } from "../../../../providers";

const DataCopy = () => {
    const {
        downloadMonthlyImaging,
    } = useWebServer();

    const [graphData, setgraphData] = useState([]);

    const getData = async () => {
        const response = await downloadMonthlyImaging({});
        const jsonData = JSON.stringify(response); // Stringify the response
        const data = JSON.parse(jsonData);
        return data;
    };

    // eslint-disable-next-line
    useEffect(async () => {
        const result = await getData();
        const data = result.data;
        // const dataforGraph = transformData(data);
        const dataforGraph = parseData(data);
        setgraphData(dataforGraph);
        // eslint-disable-next-line
    }, []);

    //format the data into graph
    //*********** */
    const formatMonth = (month) => {
        const date = new Date(month + '-02');
        return date.toLocaleString('en-us', { month: 'long' });
    };

    const parseData = (data) => {
        const graphData = [];
        const monthToDateMap = {};
        let sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
        //get last 4 months
        const last4Months = sortedData.slice(-4);

        // Get all species and initialize total count
        for (const month in last4Months) {
            console.log(last4Months[month])
            const formattedMonth = formatMonth(last4Months[month].date);
            graphData.push({ name: formattedMonth, total: last4Months[month].count });
        }

        return graphData;
    };

    console.log("graph data captures", graphData);

    const lines = [
        <Line key={0} type="monotone" dataKey="total" stroke="blue" activeDot={{ r: 8 }} />
    ];

    //******************* */
    const history = useHistory();
    // eslint-disable-next-line
    const [clickedButton, setClickedButton] = useState(null);

    const handleButtonClick = (buttonClicked) => {
        setClickedButton(buttonClicked);
        history.push(`${ROUTES.reports}?button=${buttonClicked}`);
    }

    const buttonStyle = {
        padding: '3px 3px',
        margin: '0 5px',
        flex: '1',
        fontSize: '10px',
        overflow: 'hidden',
    };

    return (
        <div style={{ maxWidth: '95%' }}>
            {/* Line graph section */}
            <div style={{ textAlign: 'center', color: '#3f51b5', fontFamily: 'Roboto' }}>
                <h2>TRAYS IMAGED PER MONTH</h2>
                {/* <div style={{ fontSize: '12px', fontWeight: '200px' }}>{'( '}Species Collection{' )'}</div> */}
            </div>

            <div style={{ marginTop: '20px', height: '350px', width: '100%', fontSize: '11px' }}>


                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={graphData}
                        margin={{
                            top: 5,
                            right: 5,
                            left: 5,
                            bottom: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" >
                            <Label value="MONTH" offset={0} position="insideBottom" style={{ fontSize: '12px', fontWeight: 'bold' }} />
                        </XAxis>
                        <YAxis>
                            <Label value="COUNT" angle={-90} position="insideLeft" style={{ textAnchor: 'middle', fontSize: '12px', fontWeight: 'bold' }} />
                        </YAxis>
                        <Tooltip />
                        {/* <Legend
                            wrapperStyle={{
                                bottom: 0,
                                marginTop: 20
                            }}
                        /> */}
                        {lines}
                    </LineChart>
                </ResponsiveContainer>

            </div>

            {/* Reports section */}
            <div style={{ textAlign: 'center', justifyContent: 'center', marginTop: '20px', color: '#3f51b5', fontFamily: 'Roboto' }}>
                <div style={{ display: 'flex', margin: '10px', flexDirection: 'column', alignItems: 'center' }}>
                    {/* Title */}
                    <div style={{ marginBottom: '5px', fontWeight: '500', fontSize: '15px' }}>REPORTS</div>
                    {/* Button styling */}
                    <div style={{ display: 'flex', width: '100%', height: '80px', overflowWrap: 'anywhere', flexDirection: 'row', maxWidth: '100%' }}>
                        <Button style={buttonStyle} variant={"contained"}
                            color={"primary"} onClick={() => handleButtonClick('Raw-Data')}>RAW DATA</Button>
                        <Button style={buttonStyle} variant={"contained"}
                            color={"primary"} onClick={() => handleButtonClick('Species-Summary-Among-Collections')}>SPECIES SUMMARY AMONG COLLECTION SITES</Button>
                        <Button style={buttonStyle} variant={"contained"}
                            color={"primary"} onClick={() => handleButtonClick('Weekly-Species-report')}>WEEKLY SPECIES REPORT</Button>
                    </div>
                </div>
                <div style={{ display: 'flex', margin: '8px', flexDirection: 'column', alignItems: 'center', color: '#3f51b5', fontFamily: 'Roboto' }}>
                    {/* Title */}
                    <div style={{ marginBottom: '5px', fontWeight: '500', fontSize: '15px' }}>GRAPHS</div>
                    {/* Button styling */}
                    <div style={{ display: 'flex', width: '100%', height: '80px', overflowWrap: 'anywhere', flexDirection: 'row', maxWidth: '100%' }}>
                        <Button style={{ ...buttonStyle, display: 'flex', flexDirection: 'column', alignItems: 'center' }} variant={"contained"}
                            color={"primary"} onClick={() => handleButtonClick('Pie-chart')}><div style={{ marginTop: '10px', }}>SPECIES</div><div> SUMMARY</div> <div style={{
                                marginTop: '8px',
                            }}><PieChartIcon fontSize="small" /></div></Button>
                        <Button style={{ ...buttonStyle, display: 'flex', flexDirection: 'column', alignItems: 'center' }} variant={"contained"}
                            color={"primary"} onClick={() => handleButtonClick('Bar-chart')}><div>SPECIES SUMMARY AMONG COLLECTION SITES</div> <div style={{
                                border: '1px solid white',
                            }}><BarChartIcon fontSize="small" /></div></Button>
                        <Button style={{ ...buttonStyle, display: 'flex', flexDirection: 'column', alignItems: 'center' }} variant={"contained"}
                            color={"primary"} onClick={() => handleButtonClick('Linegraph-chart')}><div style={{ marginTop: '10px', }}>SPECIES ABUNDANCE BY MONTH</div><div style={{
                                border: '1px solid white', marginTop: '8px',
                            }}><ShowChartIcon fontSize="small" /></div></Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataCopy;