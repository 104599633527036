import React from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Switch, Route, Redirect } from "react-router-dom";
import TabContent from "./TabContent";
import Trays from "./Trays";
// import TrayList from './TrayList';
import DetailsRouter from "./DetailsRouter";
import routes from "../../AppRouter/routes";
import DataCopy from "../ModalRouter/DataDownloadUI/DataCopy";

const SideBar = ({ onChange, pathname, getPathRoot }) => {

  const parseTabName = () => {

    if (pathname.includes('collection-site')) {
      return 'Collection Sites'
    }
    if (pathname.includes('superuser')) {
      return 'Admin'
    }
    if (pathname.includes('setup')) {
      return 'Setup'
    }
    if (pathname.includes('ste')) {
      return 'Collection Sites'
    }
    if (pathname.includes('setting')) {
      return 'settings'
    }
    return 'details'

  }

  return (
    <div className={"flex flex-col flex-1 h-full relative"}>
      <AppBar position="static"
        color={"primary"}
        sx={{
          "& .MuiTabs-root": {
            height: ['1rem', '1.5rem', '2rem', '2rem', '3rem', '3rem'],
            minHeight: ['1rem', '1.5rem', '2rem', '2rem', '3rem', '3rem'],
          },
          "& .MuiTab-root": {
            fontSize: ['.75rem', '.75rem', '.75rem', '.75rem', '1rem', '1.25rem'],
            mp: ['.5rem', '.5rem', '.5rem', '.5rem', '.75rem', '1rem'],
            py: ["0.25rem", "0.25rem", "0.75rem", "0.75rem", "0.75rem", "0.75rem"],
            height: ['1rem', '1.5rem', '2rem', '2rem', '3rem', '3rem'],
            minHeight: ['1rem', '1.5rem', '2rem', '2rem', '3rem', '3rem'],
          },
        }}
      >
        <Tabs
          value={getPathRoot({ pathname })}
          onChange={onChange}
          aria-label="navigation table"
          indicatorColor="secondary"
          variant="fullWidth"
          textColor="inherit"
          centered
        >

          {pathname.includes('data') && <Tab label={"Data"} value={routes.dataTab} id={"data-tab"} />}
          {pathname.includes('tray') && <Tab label={"Trays"} value={routes.traysTab} id={"trays-tab"} />}
          {(pathname.includes('details') || pathname.includes('site')) &&
            <Tab label={parseTabName()}
              value={routes.detailsTab} id={"details-tab"} />}
        </Tabs>
      </AppBar>
      <div className={"flex flex-col flex-1 overflow-auto"}>
        <Switch>
          <Route path={routes.dataTab}>
            <TabContent value={pathname} index={routes.dataTab}>
              <DataCopy />
            </TabContent>
          </Route>
          <Route path={routes.detailsTab}>
            <TabContent>
              <DetailsRouter />
            </TabContent>
          </Route>
          <Route path={routes.traysTab}>
            <TabContent value={pathname} index={routes.traysTab}>
              <Trays />
            </TabContent>
          </Route>
          <Route exact path={routes.app}>
            <Redirect to={routes.traysTab} value={routes.traysTab} />
          </Route>
        </Switch>
      </div>
    </div >
  );
};

export default SideBar;
