import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import {
    Box,
    Button,
    Tooltip,
    ButtonGroup,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DragIndicatorSharpIcon from "@mui/icons-material/DragIndicator";
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Star from "@mui/icons-material/Star";
import ZoomIn from "@mui/icons-material/ZoomIn";
import ZoomOut from "@mui/icons-material/ZoomOut";
import StarOutline from "@mui/icons-material/StarOutline";
import ProcessedImage from "../../../../ProcessedImage";
import ResizableIcon from "../../../../../assets/icons/resizable_icon";
import {
    percUpHardLowClip,
    getHistogram,
} from "../../../../../utils/imageProcessing";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { toggleEnlargedSpecimenModal } from '../../../../../../src/actions';
import { connect } from 'react-redux';

const buttonStyles = {
    autofillButton: {
        color: "status.info",
        borderColor: "status.info",
    },
    infoButton: {
        color: "status.info",
    },
};

const arrowButtonSx = {
    fontSize: [
        "1.45rem",
        "1.45rem",
        "1.45rem",
        "1.45rem",
        "2.1875rem",
        "2.1875rem",
    ],
};

const leftMoveUpBtnSx = {
    position: "absolute",
    left: ['1rem', '1rem', '1rem', '2rem', '2rem', '2rem'],
    marginTop: ["-31px", "-31px", "-31px", "-46px", "-46px", "-46px"],
    height: ["30px", "30px", "30px", "45px", "45px", "45px"],
    zIndex: 100,
}

const rightMoveUpBtnSx = {
    position: "absolute",
    right: ['1rem', '1rem', '1rem', '2rem', '2rem', '2rem'],
    marginTop: ["-31px", "-31px", "-31px", "-46px", "-46px", "-46px"],
    height: ["30px", "30px", "30px", "45px", "45px", "45px"],
}

const SpecimenDetails = (props) => {
    const {
        image,
        // backImage,
        // frontImage,
        // trayImage,
        handleNextSpecimen,
        handlePreviousSpecimen,
        specimenID,
        trayId,
        updatingDisplayed,
        // specimenNum,
        flipTraySide,
        isTwoSideImaged,
        toggleEnlargedSpecimenModal,
    } = props;
    const hdMatches = useMediaQuery((theme) => theme.breakpoints.up("hd"));
    const lgMatches = useMediaQuery((theme) => theme.breakpoints.up("lg"));
    const [imageSize, setSize] = useState({ x: 200, y: 200 });
    const [canImageProcess, setCanImageProcess] = useState(true);
    const [maxWidthHeight, setMaxWidthHeight] = useState(350);
    const [minWidthHeight, setMinWidthHeight] = useState(200);
    const [specimenNextButtonHiddenWidth, setSpecimenNextButtonHiddenWidth] =
        useState(250);
    const [zoomControl, setZoomControl] = useState(1);
    const [activeDrags, setActiveDrags] = useState(0);
    const [shouldProcess, setShouldProcess] = useState(true);
    const [imageRotate, setImageRotate] = useState(0)

    useEffect(() => {
        let initMaxWidth = 300;
        if (hdMatches) {
            setSize({ x: 250, y: 250 });
            initMaxWidth = 350;
            setMinWidthHeight(200);
            setSpecimenNextButtonHiddenWidth(250);
        }
        if (lgMatches) {
            setSize({ x: 300, y: 300 });
            initMaxWidth = 480;
            setMinWidthHeight(300);
            setSpecimenNextButtonHiddenWidth(350);
        }
        setMaxWidthHeight(initMaxWidth);
    }, [hdMatches, lgMatches]);

    const resizeImage = (mouseDownEvent) => {
        const startSize = imageSize;
        const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
        setCanImageProcess(false);

        function onMouseMove(mouseMoveEvent) {
            let newX = startSize.x - startPosition.x + mouseMoveEvent.pageX;
            let newY = startSize.y - startPosition.y + mouseMoveEvent.pageY;

            newX = newX > maxWidthHeight ? maxWidthHeight : newX;
            newY = newY > maxWidthHeight ? maxWidthHeight : newY;
            newX = newX < minWidthHeight ? minWidthHeight : newX;
            newY = newY < minWidthHeight ? minWidthHeight : newY;
            setSize((currentSize) => ({ x: newX, y: newY }));
        }
        function onMouseUp() {
            setCanImageProcess(true);
            document.body.removeEventListener("mousemove", onMouseMove);
        }

        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };

    const handleZoomIn = () => {
        setZoomControl(zoomControl + 0.02);
    };

    const handleZoomOut = () => {
        setZoomControl(zoomControl - 0.02);
    };

    const onStartDragButtonGroup = () => {
        setActiveDrags(activeDrags + 1);
    };

    const onStopDragButtonGroup = () => {
        setActiveDrags(activeDrags - 1);
    };

    const processSpecimen = (image) => {
        const hist = getHistogram(image);
        percUpHardLowClip(image, hist);
        return image;
    };

    const handleChangeShouldProcess = (event) => {
        setShouldProcess(!shouldProcess);
    };


    const handleOpenModal = () => {
        toggleEnlargedSpecimenModal();
    };
    const handleRotateImage = (i) => {
        setImageRotate((imageRotate + i + 360) % 360)
    }

    useEffect(() => {
        setImageRotate(0)
    }, [specimenID])

    return (
        <Box
            className={"flex"}
            id={"specimen-details-image"}
            sx={{
                justifyContent:
                    imageSize.x > specimenNextButtonHiddenWidth
                        ? "center"
                        : "space-between",
            }}
        >
            <Button
                onClick={handlePreviousSpecimen}
                id={"previous-specimen"}
                disabled={updatingDisplayed}
                sx={{
                    display: 'block',
                    minWidth: ["2.5rem", "2.5rem", "2.5rem", "3rem", "4rem", "4rem"],
                    ...(imageSize.x > specimenNextButtonHiddenWidth ? leftMoveUpBtnSx : {})
                }}
            >
                <ArrowBackIosIcon
                    // className={classes.infoButton}
                    sx={{ ...arrowButtonSx, ...buttonStyles.infoButton }}
                />
            </Button>
            <Box className={"flex flex-col relative"}>
                <Draggable
                    bounds="parent"
                    grid={[10, 10]}
                    handle="strong"
                    onStart={onStartDragButtonGroup}
                    onStop={onStopDragButtonGroup}
                >
                    <ButtonGroup
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            marginTop: "6px",
                            marginLeft: "6px",
                        }}
                    >
                        <strong style={{ cursor: "move" }}>
                            <DragIndicatorSharpIcon
                                sx={{
                                    marginBottom: ['0px', '0px', '0px', '0px', '8px', '8px'],
                                }}
                                style={{
                                    color: "rgb(66, 139, 202)",
                                    transform: "rotate(90deg)",
                                    width: "80%",
                                }}
                            />
                        </strong>
                        <Tooltip title={`Zoom In`} aria-label="zoom-in-tooltip">
                            <IconButton
                                sx={{
                                    marginBottom: ['0px', '0px', '0px', '0px', '8px', '8px'],
                                }}
                                aria-label="zoom-in-button"
                                component="span"
                                onClick={handleZoomIn}
                            >
                                <ZoomIn style={{ color: "rgb(66, 139, 202)" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Zoom Out`} aria-label="zoom-out-tooltip">
                            <IconButton
                                sx={{
                                    marginBottom: ['0px', '0px', '0px', '0px', '8px', '8px'],
                                }}
                                size="small"
                                aria-label="zoom-out-button"
                                component="span"
                                onClick={handleZoomOut}
                            >
                                <ZoomOut style={{ color: "rgb(66, 139, 202)" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Rotate -90°`} aria-label="Rotate--90°-tooltip">
                            <IconButton
                                sx={{
                                    marginBottom: ['0px', '0px', '0px', '0px', '8px', '8px'],
                                }}
                                size="small"
                                aria-label="rotate-90-button"
                                component="span"
                                onClick={() => { handleRotateImage(-90) }}

                            >
                                <Rotate90DegreesCcwIcon style={{ color: "rgb(66, 139, 202)" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Rotate 90°`} aria-label="Rotate-90°-tooltip">
                            <IconButton
                                size="small"
                                aria-label="rotate-90-button"
                                component="span"
                                onClick={() => { handleRotateImage(90) }}
                                sx={{
                                    marginBottom: ['0px', '0px', '0px', '0px', '8px', '8px'],
                                }}
                            >
                                <Rotate90DegreesCwIcon style={{ color: "rgb(66, 139, 202)" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={`Turn image enhancement ${shouldProcess ? "off" : "on"}`}
                            aria-label="image-enhancement-tooltip"
                        >
                            <IconButton
                                size="small"
                                aria-label="image-enhancement-toggle"
                                component="span"
                                onClick={handleChangeShouldProcess}
                                sx={{
                                    marginBottom: ['0px', '0px', '0px', '0px', '8px', '8px'],
                                }}
                            >
                                {shouldProcess ? (
                                    <Star style={{ color: "gold" }} />
                                ) : (
                                    <StarOutline style={{ color: "gold" }} />
                                )}
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                </Draggable>
                <Box
                    className={
                        "border-solid border-blue-500 flex justify-center items-center"
                    }
                    sx={{
                        height: imageSize.y,
                        width: imageSize.x,
                        border: "solid 2px black",
                        maxWidth: "100%",
                        minHeight: ["200px", "200px", "200px", "200px", "300px", "300px"],
                        minWidth: ["200px", "200px", "200px", "200px", "300px", "300px"],
                    }}
                >
                    <ProcessedImage
                        src={image}
                        alt={"specimen"}
                        draw={processSpecimen}
                        shouldProcess={shouldProcess}
                        rotate={imageRotate}
                        zoom={zoomControl}
                        specimenName={`tray_${trayId}_specimen_${specimenID}`}
                        width={imageSize.x}
                        height={imageSize.y}
                        canImageProcess={canImageProcess}
                    />
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: '2px',
                        left: '2px',
                        zIndex: 20,
                    }}
                    onMouseDown={handleOpenModal}
                >
                    {isTwoSideImaged && (
                        <Button size="small" variant="outlined" onClick={flipTraySide}>
                            Flip
                        </Button>
                    )}
                    <OpenInFullIcon sx={{
                        width: '50px',
                        height: '30px',
                        color: '#60A5FA',
                        padding: '1.2 px',
                        cursor: 'pointer',
                        border: '2px solid #60A5FA',
                        borderRadius: '4px',
                    }}

                    //     sx={{
                    //         position: "absolute",
                    //         bottom: 0,
                    //         right: 0,
                    //         zIndex: 20,
                    //     }}
                    //     onMouseDown={resizeImage}
                    // >
                    //     <ResizableIcon />
                    />

                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: '2px',
                        right: '2px',
                        zIndex: 20,
                    }}
                    onMouseDown={resizeImage}
                >
                    <ResizableIcon sx={{
                        width: '35px',
                        height: '35px',
                    }} />


                </Box>
            </Box>
            <Button
                onClick={handleNextSpecimen}
                id={"next-specimen"}
                disabled={updatingDisplayed}
                sx={{
                    display: "block",
                    px: ["0rem", "0rem", "0rem", ".5rem", ".5rem", ".5rem"],
                    minWidth: ["2.5rem", "2.5rem", "2.5rem", "3rem", "4rem", "4rem"],
                    ...(imageSize.x > specimenNextButtonHiddenWidth ? rightMoveUpBtnSx : {})
                }}
            >
                <ArrowForwardIosIcon
                    // className={classes.infoButton}
                    sx={{ ...arrowButtonSx, ...buttonStyles.infoButton }}
                />
            </Button>
        </Box >
    );
};

export const mapStateToProps = (state) => {
    const { isEnlargedSpecimenModalOpen } = state.enlargedspecimenR;
    return {
        isEnlargedSpecimenModalOpen
    }
};

export default connect(mapStateToProps, { toggleEnlargedSpecimenModal })(SpecimenDetails);


