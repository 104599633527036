import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import DeviceSelector from "./DeviceSelector";
import { useWebServer } from "../../../providers";
import { DEFAULT_DATA_SETTINGS } from "../../../reducers/settingsReducer";
import {
  selectDevice,
  fetchTrays,
  initAllTrays,
  fetchOrganizationFilters,
  clearTrays,
  displayPermanentWarningNotification,
} from "./../../../actions";
import { phraseDownloadDataFilter } from "../../../utils/dataDownload";
import { compareVersions } from "../../../utils/versions";
import { useHistory } from "react-router-dom";

const defaultTraySettings = _.pick(DEFAULT_DATA_SETTINGS.Tray, [
  "page",
  "pageSize",
  "order",
  "orderBy",
]);

const Index = ({
  selectDevice,
  fetchTrays,
  initAllTrays,
  fetchOrganizationFilters,
  displayPermanentWarningNotification,
  selectedDevice,
  devices,
  devicesLength,
  isLoading,
  disabled,
  queryParams,
  generalFilters,
  timeZone,
  clearTrays,
}) => {
  const { sendRequest } = useWebServer();
  const [fetchedTrays, setFetchedTrays] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchOrganizationFilters(sendRequest);
  }, [fetchOrganizationFilters, sendRequest]);

  const fetchTraysHandler = _.debounce(
    (generalFilters, timeZone, devices, selectedDevice) => {
      let filters = phraseDownloadDataFilter(generalFilters, timeZone);
      //clear all tray on the tool
      Promise.all(
        Object.entries(devices).map(([deviceUuid, { id }]) =>
          clearTrays(deviceUuid)
        )
      );
      let deviceList = Object.entries(devices);
      if (selectedDevice) {
        //move the selected device to the front of the list for faster loading
        const selectedDeviceIndex = deviceList.findIndex(
          (val) => val[0] === selectedDevice
        );
        const cur = deviceList[selectedDeviceIndex];
        deviceList.splice(selectedDeviceIndex, 1);
        deviceList.unshift(cur);
      }
      initAllTrays(
        deviceList,
        { ...defaultTraySettings, ...filters },
        sendRequest,
        filters
      );
      setFetchedTrays(true);
    },
    1000,
    { leading: true, trailing: true }
  );

  //eslint-disable-next-line
  const debounceFetchTrays = useCallback(fetchTraysHandler, []);

  useEffect(() => {
    if (history.location.pathname === "/data") {
      return;
    }
    if (
      !fetchedTrays &&
      _.isObject(devices) &&
      Object.entries(devices).length > 0
    ) {
      debounceFetchTrays(generalFilters, timeZone, devices, selectedDevice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, fetchedTrays, sendRequest]);

  useEffect(() => {
    setFetchedTrays(false);
  }, [generalFilters]);

  useEffect(() => {
    if (Object.values(devices).length > 1) {
      let lowVersionDevices = Object.values(devices).reduce((arr, d) => {
        if (
          !(
            (typeof d?.algVersion === "string" ||
              d?.algVersion instanceof String) &&
            !d?.retired
          )
        )
          return arr;
        if (compareVersions(d.algVersion, "v5.0.0") === -1) {
          return [...arr, d.displayName];
        }
        return arr;
      }, []);
      console.log(
        `Found ${
          lowVersionDevices.length
        } low version devices out of a possible ${
          Object.values(devices).length - 1
        }`
      );
      const numLVDevices = lowVersionDevices.length;
      if (lowVersionDevices.length >= 5) {
        lowVersionDevices = [
          ...lowVersionDevices.slice(0, 4),
          `${numLVDevices - 4} more devices`,
        ];
      }
      if (lowVersionDevices.length > 0) {
        displayPermanentWarningNotification(
          `You have ${numLVDevices} devices (${lowVersionDevices.join(
            ", "
          )}) that are in need of an update. Until these devices are updated, empty detection and sex classification may not work correctly for new captures.`,
          "lowVersionDevicesWarning"
        );
      }
    } else {
      console.log(`devices.length: ${Object.values(devices).length}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesLength, displayPermanentWarningNotification]);

  const onChangeDevice = (obj) => {
    if (typeof obj?.target?.value === "string") {
      selectDevice(obj.target.value);
      const toolId = devices?.[obj.target.value]?.id;
      if (toolId) {
        fetchTrays(toolId, queryParams, sendRequest, obj.target.value);
      }
    }
  };
  return (
    <DeviceSelector
      devices={devices}
      selectedDevice={selectedDevice ? selectedDevice : "none"}
      handleChangeDevice={onChangeDevice}
      loading={isLoading}
      disabled={disabled}
    ></DeviceSelector>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  const { capturing = false } = state.capture;
  const { confirmed: licenseConfirmed = "UNKNOWN" } = state.license;
  const { status: wifiSetupStatus } = state.wifi;
  const { status: wsStatus } = state.websocket;
  const { fetchingMacAddressStatus } = state.devices;
  const queryParams = _.pick(state.settings.Tray, [
    "page",
    "pageSize",
    "order",
    "orderBy",
  ]);
  const disabled =
    wsStatus !== "open" ||
    capturing ||
    licenseConfirmed === "PENDING" ||
    !(wifiSetupStatus === "disconnected" || wifiSetupStatus === "ready") ||
    !(
      fetchingMacAddressStatus === "disconnected" ||
      fetchingMacAddressStatus === "ready"
    );
  const isLoading =
    wsStatus !== "open" || !devices || Object.keys(devices).length === 0;

  const generalFilters = state.data?.generalFilters || {};
  const { timeZone } = state.user;
  const devicesObj = _.pickBy(
    devices,
    (value, _) =>
      value?.env === process.env.REACT_APP_ENV.toUpperCase() ||
      value?.env === "ALLTRAY"
  );
  return {
    devices: devicesObj,
    devicesLength: Object.values(devicesObj).length,
    isLoading,
    selectedDevice,
    disabled,
    queryParams,
    generalFilters,
    timeZone,
  };
};

export default connect(mapStateToProps, {
  selectDevice,
  initAllTrays,
  fetchTrays,
  fetchOrganizationFilters,
  clearTrays,
  displayPermanentWarningNotification,
})(Index);
