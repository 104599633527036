import React from "react";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import ModalContainer from "../ModalContainer";
import TrayFilterModal from "./TrayFilterModal";
import {
  updateGeneralFilters,
  clearGeneralFilters,
  updateIsTrayFiltersModal,
} from "./../../../../actions";
import _ from "lodash";

const Index = ({
  updateGeneralFilters,
  options,
  generalFilters,
  updateIsTrayFiltersModal,
  isOpen,
  clearGeneralFilters,
}) => {
  const boxHeight = ["560px", "560px", "560px", "660px", "770px", "930px"];

  const handleClose = () => updateIsTrayFiltersModal(false);

  return (
    <ModalContainer
      size={{ height: boxHeight }}
      onClickOutsideModal={handleClose}
      open={isOpen}
    >
      <Paper
        className={
          "flex bg-white rounded-xl border-4 border-transparent transition-opacity duration-1000"
        }
        sx={{
          height: boxHeight,
          width: ["100vw", "544px", "544px", "544px", "824px", "824px"],
          display: isOpen ? "block" : "none",
        }}
      >
        <TrayFilterModal
          updateGeneralFilters={updateGeneralFilters}
          options={options}
          generalFilters={generalFilters}
          clearGeneralFilters={clearGeneralFilters}
          handleClose={handleClose}
        ></TrayFilterModal>
      </Paper>
    </ModalContainer>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  const { count, ...trapSites } = state.trapSites;
  const {
    contributedGenusSpecies = [],
    plateWells = {},
    trapTypes: trapTypes = [],
    algorithmGenusSpecies = [],
    users = [],
    tags = [],
  } = state.organization;
  const contributedPlates = Object.keys(
    _.pickBy(plateWells, (plate, wells) => wells.length > 0)
  );
  const { timeZone } = state.user;
  const { generalFilters } = state.data || {};
  const curDevices = Object.values(
    _.pickBy(
      devices,
      (value, _) => value?.env === process.env.REACT_APP_ENV.toUpperCase()
    )
  );
  const options = {
    deviceOptions: curDevices,
    trapSiteOptions: Object.values(trapSites),
    contributedGenusSpeciesOptions: contributedGenusSpecies,
    contributedPlatesOptions: contributedPlates,
    trapTypesOptions: trapTypes,
    algorithmGenusSpeciesOptions: algorithmGenusSpecies,
    usersOptions: users,
    tagsOption: tags,
  };

  return {
    devices: curDevices,
    options,
    selectedDevice,
    timeZone,
    generalFilters,
    isOpen: state.data.isTrayModalOpen,
  };
};

export default connect(mapStateToProps, {
  updateGeneralFilters,
  updateIsTrayFiltersModal,
  clearGeneralFilters,
})(Index);
